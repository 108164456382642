@import url('https://fonts.googleapis.com/css2?family=Inclusive+Sans&display=swap');

:root {
  --mainBlue: #284580;
  --secondaryBeige: #FADBB3;
  --thirdWhite: #FCFAF9;
  --fourthpurple: #BD8B9C;
}

* {

  font-family: 'Inclusive Sans', sans-serif;
  font-weight: 400;
}

.App {
  text-align: center;
  font-size: 18px;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.Layout {
  width: 100%;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  display: flex;
  align-items: flex-start;
  // margin: 1rem 0;
}