.button-end {
    background-color: var(--mainBlue);
    border-radius: 10px;
    padding: .5rem 2rem;

    a {
        color: var(--secondaryBeige);
        text-decoration: none;
        margin: 1rem;
        font-size: 1rem;
    }
}