.error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 1rem;

    p,
    a {
        font-size: 1rem;
        color: var(--mainBlue);
    }

    &-img {
        width: 50%;
        height: auto;
        border-radius: 50%;
        margin: 1rem 0;
    }

}

@media (min-width: 720px) {
    .error {
        justify-content: space-around;

        &-img {
            width: 25%;

        }

        p,
        a {
            font-size: 2.5rem;
        }
    }

}