.Footer {
    // width: 100%;
    // height: 15rem;
    background-color: var(--mainBlue);
    color: var(--thirdWhite);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    // padding: 2rem 0;

    &-container {
        // display: flex;
        width: 100%;
        padding: 1rem 0;
        line-height: 1.5rem;
        justify-content: space-around;
    }

    p {
        margin: 0;

        a {
            // color: var(--thirdWhite);

        }
    }

    a {
        text-decoration: none;
        color: var(--thirdWhite);

        &:hover {
            text-decoration: underline;
        }
    }
}

@media (min-width: 720px) {
    .Footer-container {
        display: flex;
    }
}