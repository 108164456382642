.contact {
    width: 100%;
    margin-bottom: 1rem;

    .error {
        font-size: .8rem;
        margin: 0;
        color: var(--thirdWhite);
    }

    &-title {
        background-color: var(--mainBlue);
        padding: 1rem 0;
        margin: 0;
        color: var(--thirdWhite);
    }

    a {
        font-size: 1.5rem;
    }

    &-form {
        background-color: var(--mainBlue);
        margin: 2rem;
        padding: 1rem;
        border-radius: 10px;

        .input-wrapper {
            display: flex;
            flex-direction: column;
            margin: 1rem 0;

            label {
                margin: 1rem 0;
                color: var(--secondaryBeige);
            }

            input {
                line-height: 1.5rem;
                border-radius: 5px;
                font-size: 1.2rem;
            }

            #Message {
                height: 5rem;
                font-size: 1.2rem;
            }
        }

        .contact-send {
            background-color: var(--secondaryBeige);
            color: var(--mainBlue);
            box-shadow: 0px 0px 2px var(--secondaryBeige);
        }

        &-sent {
            padding: 1rem 0;

        }
    }

    &-send {
        width: 50%;
        padding: .5rem;
        border-radius: 10px;
        color: var(--thirdWhite);
        background-color: var(--mainBlue);
    }
}

@media (min-width: 720px) {
    .contact {
        &-form {
            padding: 1rem;
            width: 100%;
            margin: 0;
            padding: 0 0 1rem 0;

            div {
                display: flex;
                justify-content: space-around;
                width: 100%;

                .input-wrapper {

                    input,
                    label {
                        margin: 0 2rem;
                        text-align: initial;
                        padding: .5rem 0;
                    }
                }


            }

        }

    }

    #Message {
        margin: 0 1rem;
    }

    .form-container {
        padding: 1rem;
    }


}