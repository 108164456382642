.scroll {
    background-color: var(--secondaryBeige);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mainBlue);
    position: fixed;
    bottom: 100px;
    right: 10px;
    cursor: pointer;

    &:hover {
        background-color: var(--mainBlue);
        color: var(--secondaryBeige);
    }
}