.about {
    width: 100%;
    margin-bottom: 1rem;

    &-text {
        margin: 1rem;
        line-height: 2rem;
    }

    &-title {
        background-color: var(--mainBlue);
        padding: 1rem 0;
        margin: 0;
        color: var(--thirdWhite);
    }

    &-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
    }

    &-list {
        padding: 0 .3rem;
        text-align: left;

        &-item {
            justify-content: left;
            width: 100%;
            line-height: .5rem;
            margin: 1rem 0;
            line-height: 2rem;
            display: list-item;
            list-style-position: inside;

        }
    }

    &-title-perso {
        width: 100%;
        margin: 0;
        padding: 1rem 0;
        background-color: var(--mainBlue);
        color: var(--secondaryBeige);
    }
}

@media (min-width: 720px) {
    .about {
        &-img {
            width: 200px;
            height: 200px;
        }

        &-list {
            padding-right: 0;

            &-item {
                margin: 0;
            }
        }
    }


}