.endo {
    margin-bottom: 1rem;

    &-text {
        text-align: initial;
        margin: 0 .5rem;
    }

    &-title {
        background-color: var(--mainBlue);
        padding: 1rem 0;
        margin: 0;
        color: var(--thirdWhite);
    }

    &-subtitle {
        color: var(--mainBlue);
    }

    &-bande {
        width: 100%;
        height: auto;
    }

    &-problem,
    &-despress,
    &-infertility {
        margin-bottom: 3rem;
        font-size: 1rem;

        &-pic {
            width: 100%;
            height: auto;
        }

        &-description {

            h4 {
                font-size: 1.5rem;
                color: var(--mainBlue);
            }


        }

        &-list {
            margin: 0;
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            margin-left: 1rem;
        }

        &-item {
            margin: .5rem 0;
            width: 100%;
            justify-content: flex-start;
            display: list-item;
            text-align: initial;
        }

    }


}

@media screen and (min-width:480px) {
    .endo {

        &-problem,
        &-despress,
        &-infertility {
            &-pic {
                width: 50%;
                border-radius: 10px;

            }
        }

        // img {
        // }
    }
}

@media screen and (min-width:764px) {
    .endo {

        &-problem,
        &-despress,
        &-infertility {
            display: flex;
            width: 100%;

            &-pic {
                width: 25%;
            }

            &-description {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 1rem;
                justify-content: center;

                h4 {
                    margin: 0;
                }
            }

            // &-item {
            //     margin: .1rem;
            // }
        }
    }
}

@media screen and (min-width:920px) {
    .endo {
        &-bande {
            height: 200px;
            object-fit: cover;
        }

        &-text {
            margin: 0 1rem;
        }



        &-problem,
        &-despress,
        &-infertility {
            display: flex;
            width: 100%;
            font-size: 1rem;
            margin-left: 2rem;

            &-pic {
                width: 20%;
                height: 10%;
            }

            &-list {
                display: flex;
                width: 50%;
                justify-content: space-evenly;

                h4 {
                    font-size: 2rem;
                }
            }
        }
    }
}